.gray-block {
  @apply bg-blue-pattern py-14 text-center xl:py-32;
}

.gray-section {
  @apply bg-blue-pattern py-15 lg:py-20 ;
  
  &__title  {
    @apply leading-relaxed mb-10 md:text-4xl lg:text-5xl lg:leading-snug lg:mb-15;
    
    @media only screen and (min-width: 767px) {
      max-width: 400px;
      
      
    }
    
  }
  
  p {
    @apply lg:pb-15;
  }
  
  &__subtitle {
    @apply text-blue text-xl block mb-4;
  }
  
  &__text {
    @apply mb-12 md:w-1/2 md:mb-0 md:pr-8;
  }
}

.blue-wrap {
  @apply md:flex md:flex-wrap;
  
  &__hold {
    @apply  md:w-1/2;
  }
}


.blue-block {
  @apply bg-blue text-white py-8 px-12 text-xl pb-4 mb-10 md:ml-auto md:mb-0 lg:text-3xl lg:tracking-tighter md:px-4;
  
  @media only screen and (min-width: 767px) {
    margin-top: -80px;
  }
  
  @media only screen and (min-width: 1023px) {
    max-width: 560px;
    margin-top: -130px;  
    padding: 111px 60px 111px 80px;
  }
  
  &__list {
    counter-reset: counter;
    
    li {
      padding: 14px 15px 14px 57px;
      @apply relative mb-3 lg:mb-10;
      
      @media only screen and (min-width: 1023px) {
        padding: 20px 10px 20px 89px;
      }
      
      &:before {
        counter-increment: counter;
        content: counter(counter);
        @apply absolute w-9 h-9  border-white border text-center rounded-full border-solid py-0.5 lg:w-15 lg:h-15 lg:text-32px lg:py-2;
        box-sizing: border-box;
        top: 10px;
        left: 2px;
      }
    }
  }
}

.welfare {
  @apply bg-cover bg-no-repeat bg-center flex flex-wrap items-end z-10 relative;
  height: 813px;
  
  @media only screen and (min-width: 767px) {
    height: auto;
  }
  
  &__text {
    @apply bg-green-secondary -mx-4 px-4 bg-opacity-80 text-white pb-14 pt-11 md:bg-opacity-0 md:w-1/2 md:ml-auto ;
    
    @media only screen and (min-width: 1199px) {
      padding: 128px 30px 128px 84px;
    }
    
    &:before {
      content: '';
      @apply absolute top-0 bottom-0 right-0 w-1/2 bg-green-secondary bg-opacity-80 hidden md:block;
      z-index: -1;
    }
  }
  
  
  &__title {
    @apply text-3xl font-bold tracking-tighter mb-8 relative pt-6 md:text-4xl lg:text-5xl lg:pt-11 lg:mb-14;
    
    &:before {
      content: '';
      @apply absolute left-0 top-0 h-1 w-24 bg-white;
    }
  }
  
  &__paragraph {
    @apply text-gray mb-8 text-lg leading-relaxed lg:text-xl lg:leading-normal xl:mb-28;
  }
  
  &__btn {
    @apply no-underline  border-b-4 border-solid border-white text-white text-lg font-semibold inline-block lg:text-xl lg:pb-0.5 hover:opacity-80 transition; 
  }
}


.tabset {
  @apply hidden md:block text-xl lg:text-28px md:w-5/12 pl-4 lg:pl-7 md:pr-4;
  
  @media only screen and (min-width: 1023px) {
    padding-right: 12%;
  }
  
  &__item { 
    @apply relative;
    
    
  }
  
  &__link {
    @apply no-underline text-gray-light py-4 lg:py-7 block border-b border-solid pl-2;
    border-color: #C8CED8;
    
    &:before {
      content: '';
      @apply bg-green-secondary absolute -left-4 top-1/2 transform -translate-y-1/2 h-10 w-2 lg:-left-8 opacity-0 transition;
    }
    
    &.active,
    &:hover {
      @apply text-blue;
      
      &:before {
        @apply opacity-100;
      }
      
      .tabset__icon {
        fill: #343D72;
      }
    }
  }
  
  &__icon {
    @apply w-7 h-7 transform -rotate-90 ml-2 inline-block align-middle -mt-1;
    fill: #808794;
  }
}

.tab {
  
  &__hold {
    @apply md:flex md:flex-wrap;
  }
  
  &__title {
    @apply text-center text-3xl tracking-tighter mb-11 md:text-left md:text-4xl lg:text-5xl xl:mb-18;
  }
  
  &__caption {
    @apply bg-gray-secondary text-white px-4 pt-4 pb-7 text-base w-full md:flex md:flex-wrap md:bg-opacity-60 md:items-center;
    
    @media only screen and (min-width: 1023px) {
      padding: 30px 56px 30px 116px;
    }
    
    p {
      @apply mb-7 md:mb-0 md:flex-1 lg:text-lg;
      
      @media only screen and (min-width: 767px) {
        padding-right: 11%;
      }
    }
  }
  
  &__btn {
    @media only screen and (min-width: 767px) {
      width: 164px;
      flex-basis: 164px;
    }
  }
  
  &__img {
    height: 376px;
    @apply flex flex-wrap items-end bg-cover bg-no-repeat bg-center;
    
    @media only screen and (min-width: 1023px) {
      height: 549px;
    }
  }
  
  
  &__item {
    @apply md:absolute md:left-0 md:right-0 md:top-0 md:opacity-0 md:invisible transition;
    
    &.active {
      
      @apply md:opacity-100 md:visible md:static;
      
      .accordion__icon {
        @apply rotate-0;
      }
    }
  }
  
  &__content {
    @apply relative md:w-7/12;
    
    
  }
}
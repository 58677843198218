/* http://meyerweb.com/eric/tools/css/reset/ 
v2.0 | 20110126
License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

p {
	margin-bottom: 1rem;
}

/* Custom Hububble Reset */

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
}


.container {
	@apply mx-auto px-4 w-full;
	max-width: 1524px;
}

.btn {
	@apply transition border-0 bg-green text-white inline-block align-top px-6 py-2 no-underline text-base lg:text-xl lg:py-3 lg:px-8;
	
	&-big {
		@apply text-xl py-3 px-8;
	}
	
	&:hover {
		@apply bg-green-secondary text-white;
	}
}



.btn-underline {
	@apply text-green border-b-4 border-solid border-green pb-1 ml-2 font-bold no-underline text-xl; 
}





.heading {
	@apply text-center mb-16 md:mx-auto md:text-xl lg:text-2xl mb-18 text-gray-secondary;
	
	@media only screen and (min-width: 767px) {
		max-width: 830px;
	}
	
	
	&--small {
		@apply lg:text-xl leading-loose;
	}
	
	&__title {
		@apply  tracking-tighter mb-5 text-3xl  md:text-4xl lg:text-5xl lg:mb-9 text-gray;
	}
}




ol.list {
	counter-reset: list;
	margin-left: 40px;
	margin-top: 38px;
	@apply overflow-hidden xl:mt-24 lg:ml-0 lg:text-2xl; 
	
	
	li {
		counter-increment: list;
		@apply pb-6 relative  lg:pb-9;
		
		&:before {
			content: counter(list);
			@apply bg-green text-white w-7.5 h-7.5 inline-flex items-center justify-center mr-3.5 rounded-full z-20 lg:w-9 lg:h-9 lg:mr-8 ;
		}
		
		&:after {
			content: '';
			@apply bg-green w-px absolute inset-y-0 left-3.5 -z-10  w-0.5;
			
			@media only screen and (min-width: 1023px) {
				left: 18px;
			}
		}
		
		&:first-child:after {
			@apply top-1/2;
		}
		
		&:last-child {
			@apply pb-0;
			
			&:after {
				@apply bottom-1/2;
			}
		}
	}
}



.content {
	@apply md:flex-1;
	
	@media only screen and (min-width: 1199px) {
		padding-left: 10.1%;
	}
}

#main {
	@apply pt-16 md:pt-20 lg:pt-25;
}







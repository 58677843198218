
.num-list {
  @apply mx-auto md:flex md:flex-wrap lg:-mx-18 xl:m-auto;
  max-width: 153px;
  
  @media only screen and (min-width: 767px) {
    max-width: none;
  }
  
  @media only screen and (min-width: 1023px) {
    max-width: 1300px;
  }
  
  &__item {
    @apply mb-14 md:w-1/3 px-4 lg:px-18;
    
    
  }
  
  &__title {
    @apply text-6xl font-semibold font-serif block border-b-4 border-solid border-green-secondary pb-3 mb-2 md:pb-5 md:mb-5;
    
    @media only screen and (min-width: 1023px) {
      font-size: 70px;
      max-width: 200px;
    }
  }
  
  &__sub {
    @apply text-xl lg:text-3xl;
  }
  
  &__text {
    @apply tracking-wider lg:text-3xl;
  }
}

.main-header {
  @apply m-0 block pt-6 pb-2 absolute left-0 right-0 top-0 z-10 bg-white z-50 md:pb-6; 
}

.main-nav {
  
  @apply lg:flex lg:flex-wrap items-center;
  
  &__header {
    width: 125px;
    
    
    @media only screen and (min-width: 1023px) {
      width: 185px;
      flex-basis: 185px;
    }

    a {
      @apply flex flex-wrap items-center;
    }
  }
  
  &__drop {
    @apply absolute left-0 right-0 top-full max-h-0 overflow-hidden lg:static lg:flex-1 lg:overflow-visible;
    transition: max-height 0.3s;
    
    @media only screen and (min-width: 1023px) {
      max-height: none;
    }
    
    
    .nav-active & {
      max-height: 9999px;
    }
  }
  
  &__wrap {
    @apply transform -translate-y-full bg-blue-pattern pb-14 lg:translate-y-0 lg:bg-transparent lg:flex lg:flex-wrap lg:justify-end lg:items-center lg:pb-0;
    transition: transform 0.3s;
    
    .nav-active & {
      @apply translate-y-0;
    }
  }
  
  &__opener {
    @apply top-5 right-4 absolute w-9 h-9 lg:hidden;
    
    
    &:hover {
      opacity:.9; 
    }
    
    .nav-active & {
      span {
        opacity: 0;
      }
      
      &:before,
      &:after {
        transform:rotate(45deg);
        top:50%;
        left:15%;
        right:15%;
      }
      &:after {
        transform:rotate(-45deg);
      }
    }
    
    span,
    &:before,
    &:after {
      @apply bg-blue-secondary absolute -mt-2 h-0.5;
      top:51%;
      left:15%;
      right:15%;
      transition:all .3s linear;
    }
    
    &:before,
    &:after {
      content:'';
      top:30%;
    }
    
    &:after {
      top:73%;
    }
  }
  
  
  .btn {
    @apply ml-6 lg:ml-18;
  }
  
}




.language {
  @apply hidden lg:block lg:text-right relative;
  
  @media only screen and (min-width: 1023px) {
    width: 92px;
  }
  
  &__opener {
    @apply text-blue no-underline font-medium text-lg relative inline-block pr-5;
    
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 0 6px;
      border-color: #4c5cb4 transparent transparent transparent;
      @apply absolute right-0 top-1/2 transform -translate-y-1/2;
    }
  }
  
  &__list {
    @apply absolute -right-4 w-24 top-full bg-white overflow-hidden text-center ;
    
    @media only screen and (min-width: 1023px) {
      margin-top: 34px;
    }
  }
  
  &__link {
    @apply no-underline text-blue hover:text-green block py-1 px-2;
  }
}

.menu-list {
  @apply text-lg mb-9 lg:flex lg:flex-wrap lg:mb-0 ;
  
  &__item {
    border-color: #C8CED8;
    @apply border-b border-solid lg:border-transparent lg:ml-6 xl:ml-18 lg:relative;
    
    
    &.hover {
      .menu-list__link {
        .menu-list__txt:before {
          @apply rotate-90 lg:rotate-90;
        }
      }
    }
    
    
    &.active {
      .menu-list__link {
        @apply text-green;
        
        &:before {
          @apply opacity-100;
        }
        
        .menu-list__txt {
          &:before {
            border-color: transparent transparent transparent #269758;
          }
        }
      }
    }
    
    &:hover {
      .menu-list__link {
        @apply text-green;
        
        &:before {
          @apply opacity-100;
        }
        
        .menu-list__txt {
          &:before {
            border-color: transparent transparent transparent #269758;
          }
        }
      }
      
      .dropdown {
        @apply lg:opacity-100 lg:visible;
      }
    }
    
    
  }
  
  &__link {
    @apply no-underline text-blue block px-6 py-5 lg:p-0; 
    
    &:before {
      content: '';
      margin-bottom: -36px;
      @apply hidden md:block absolute left-0 right-0 bottom-0 h-1 bg-green opacity-0 transition;
    }
  }
  
  .has-dropdown-a {
    .menu-list__txt {
      @apply relative pr-6;
      
      &:before {
        content: '';
        @apply right-0 top-1/2 transform -translate-y-1/2 absolute md:rotate-90;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 7px;
        border-color: transparent transparent transparent #4c5cb4;
      }
    }
  }
  
  
}

.dropdown {
  @apply max-h-0 overflow-hidden lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:bg-white lg:py-5 lg:pb-3 lg:px-9 lg:top-full lg:mt-9 lg:overflow-visible lg:opacity-0 lg:invisible;
  transition: max-height 0.3s, opacity 0.3s, visibility 0.3s;
  
  &:before {
    content: '';
    @apply lg:left-0 lg:right-0 lg:absolute z-10 lg:h-9 lg:-top-9 z-10;
    
    
  }
  
  @media only screen and (min-width: 1023px) {
    max-height: none;
    width: 169px;
  }
  
  .hover & {
    max-height: 500px;
  }
  
  
  
  
  
  
  &__item {
    @apply lg:mb-3
  }
  
  &__link {
    @apply block px-6 py-4 no-underline text-blue bg-blue-lighter lg:bg-white lg:p-0;
    
    &:hover {
      @apply text-green;
    }
  }
}


.logo {
  @apply flex flex-wrap items-center justify-center -mx-4;
  
  &__item {
    @apply w-1/2 text-center mb-10 md:w-1/6 px-4;
  }
  
  &__link {
    max-width: 44%;
    @apply block mx-auto;
    
    @media only screen and (min-width: 767px) {
      max-width: none;
    }
  }
}
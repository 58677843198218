.accordion {
  &__opener {
    @apply relative text-left no-underline text-gray text-xl pl-2 pr-8 block py-4 border-b border-solid border-gray-lighter mb-4 md:hidden;
  }
  
  &__slide {
    @apply overflow-hidden ;
    transition: 0.3s height;
    
    @media only screen and (min-width: 767px) {
      height: auto !important;
    }
  }
  
  
  &__icon {
    @apply absolute right-0 top-1/2 transform -translate-y-1/2 w-4 -rotate-90;
    transition: transform 0.3s;
    
  }
}


.life {
  @apply -mt-3 pb-6 lg:pt-5 lg:pb-9;
  
  
  &__title {
    pointer-events: none;
    @apply no-underline block text-center text-xl text-blue mb-5 md:block lg:text-3xl md:text-left md:mb-0 py-7;
    
    @media only screen and (min-width: 767px) {
      pointer-events: auto;
      
    }
  }
  
  &__item {
    @apply border-b border-solid border-gray-light;
    
    @media only screen and (min-width: 767px) {
      max-width: 600px;
    }
    
    &.active {
      .life__icon {
        @apply rotate-90;
      }
    }
  }
  
  .swiper-wrapper {
    @apply md:block;
  }
  
  &__paragraph,
  p {
    @apply md:text-lg md:mb-1;
  }
  
  &__wrap {
    @apply xl:pb-6;
  }
  
  &__slide {
    @apply overflow-hidden ;
    transition: max-height 0.3s;
    
    @media only screen and (min-width: 768px) {
      height: auto !important;
    }
  }
  
  
  &__icon {
    @apply absolute right-0 top-1/2 transform -translate-y-1/2 hidden md:block w-5 h-5;
    transition: transform 0.3s;
  }
  
  &__txt {
    @apply inline-block md:relative md:pr-6 ;
  }
}


.col {
  
  
  @apply md:flex md:flex-wrap md:-mx-4 text-gray-secondary;
  
  &__img1 {
    padding-right: 8%;
    @apply relative;
    
    &:before {
      content: '';
      @apply bg-blue-pattern absolute -bottom-6 -right-0 w-24 h-24 lg:-right-20 lg:-bottom-12;
      z-index: -1;
      
      @media only screen and (min-width: 1023px) {
        width: 169px;
        height: 169px;
      }
    }
    
    @media only screen and (min-width: 767px){ 
      padding-right: 0;
    }
  }
  
  &__img2 {
    margin-top: -8%;
    margin-left: 7%;
    width: 58%;
    @apply relative;
    
    @media only screen and (min-width: 767px) {
      width: 64%;
      margin: -8% auto 0;
    }
  }
  
  &__item {
    @apply mb-6 md:w-1/2 md:px-4;
  }
  
  &__img3 {
    @apply mb-10;
    
    @media only screen and (min-width: 767px) {
      padding-top: 11.6%;
      margin-left: auto;
      width: 81.2%;
      margin-bottom: 19.3%;
    }
  }
  
  &__title {
    @apply text-center mb-6 md:text-4xl lg:text-5xl md:text-left lg:mb-11 text-gray; 
  }
  
  &__paragraph {
    @apply lg:text-xl lg:leading-relaxed;
  }
  
  &__txt {
    @apply text-green text-center block text-lg py-4 md:text-left;
  }
  
}


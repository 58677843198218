.text-block {
  @apply mb-16 md:flex md:flex-wrap md:-mx-4;
  
  
  &__title {
    @apply md:w-1/3 md:text-3xl lg:text-5xl md:px-4;
  }
  
  &__hold {
    max-width: 854px;
  }
  
  &__group {
    @apply md:w-2/3 md:px-4 lg:text-xl lg:leading-relaxed md:pt-2;
  }
}


.value {
  @apply text-center md:flex md:flex-wrap md:-mx-4;
  
  &__heading {
    @apply text-center md:text-4xl lg:text-5xl xl:mb-25;
  }
  
  &__item {
    @apply mb-15 md:w-1/3 md:px-4;
  }
  
  
  &__img {
    @apply w-25 mx-auto lg:mb-2;
    
    @media only screen and (min-width: 1023px) {
      width: 140px;
      height: 140px;
    }
  }
  
  &__title {
    @apply text-xl text-gray block lg:text-2xl ; 
  }
}



.comment {
  @apply py-5 -mx-4 px-4 bg-cover bg-no-repeat bg-center relative z-10 flex flex-wrap items-center justify-center text-center md:mx-0;
  min-height: 354px;
  
  @media only screen and (min-width: 1023px) {
    min-height: 390px;
  }
  
  &:before {
    content: '';
    z-index: -1;
    mix-blend-mode: multiply;
    background: #F1F7EF;
    @apply absolute left-0 right-0 top-0 bottom-0;
  }
  
  &__title {
    @apply mb-16 md:text-4xl lg:text-5xl xl:mb-20;
  }
  
}


.icon-list {
  @apply text-center md:flex md:flex-wrap -mx-4 ;
  
  &__item {
    @apply mb-16 pb-12 mb-12 relative md:w-1/3 px-4 lg:mb-16 ;
    
    &:last-child {
      @apply mb-6;
      
      &:before {
        display: none;
      }
    }
    
    &:before {
      content: '';
      @apply absolute left-1/2 bottom-0 transform -translate-x-1/2 bg-gray-light h-px w-14 md:hidden;
    }
  }
  
  &__img {
    @apply mb-3  w-20 mx-auto h-20 flex flex-wrap items-center md:mb-8;
    
    
    @media only screen and (min-width: 1023px) {
      width: 100px;
      height: 100px;
    }
  }
  
  &__title {
    @apply text-lg md:text-xl lg:text-2xl;
  }
}

.testimonial {

  @apply md:flex md:flex-wrap md:w-10/12 md:mx-auto md:items-center;
  
  &__img {
    width: 159px;
    height: 159px;
    @apply rounded-full mx-auto mb-7 overflow-hidden md:mr-4 lg:mr-5 xl:mr-16;

    @media only screen and (min-width: 767px) {
      width: 313px;
      flex-basis: 313px;
      height: 313px;
    }

  
  }
  

  &__text {
    @media only screen and (min-width: 767px) {
      flex: 1;
    }
  }
  
  &__blockquote {
    @apply px-6 py-24 pb-10 relative xl:px-20 xl:py-24 xl:pb-12;
  }
  
  &__quote {
    @apply block mb-12 lg:text-xl lg:leading-relaxed xl:mb-6;
    
    &:before {
      content: '';
      @apply absolute left-6 top-0 bg-green-secondary h-1 w-20 mt-16 xl:left-20 xl:w-24 ;
    }
  }
  
  &__cite {
    @apply text-base;
  }
  
  .quote-start {
    @apply absolute left-1 w-16  w-11 h-11 top-0 ;
  }
  
  .quote-end {
    @apply absolute right-1 w-16  w-11 h-11 bottom-0 ;
  }
  
  &__name {
    @apply ml-2 pl-2 relative ;
    
    &:before {
      content: '|';
      @apply left-0 top-1/2 transform -translate-y-1/2 absolute;
    }
  }
  
  
}




.brand {
  @apply md:flex md:flex-wrap md:-mx-4 xl:-mx-8;
  
  &__item {
    @apply mb-5 md:px-4 xl:px-8 md:w-1/3;
  }
  
  &__link {
    min-height: 213px;
    @apply flex flex-wrap items-center justify-center no-underline text-gray text-center border border-solid px-15 py-5 hover:border-blue transition;
    border-color: #C8CED8;
    
    @media only screen and (min-width: 1023px) {
      height: 283px;
    }
  }
  
  &__img {
    @apply mb-7 lg:mb-12;
  }
}



.brand-info {
  @apply mb-9 md:pt-2 lg:flex lg:flex-wrap lg:items-start lg:mb-14 overflow-hidden;
  
  
  &__hold {
    @media only screen and (min-width: 1023px) {
      width: 281px;
      flex-basis: 281px;
      padding-right: 7%;
    }
  }
  
  &__title {
    @apply text-sm text-gray-secondary block relative mb-8;
    
    &:after {
      content: '';
      @apply absolute top-1/2 transform -translate-y-1/2 right-0 left-0 bg-border h-px; 
      right: -9999px;
      
      
    }
    
    &:before {
      content: '';
      @apply absolute left-0 top-1/2 transform -translate-y-1/2 bg-border w-1 h-1 z-10;
    }
  }
  
  &__txt {
    @apply bg-white inline-block px-4 relative z-1;
  }
  
  &__img {
    @apply text-center mb-9;
  }
  
  &__wrap {
    @apply text-gray bg-border border border-solid border-blue-pattern bg-gray-lightest text-base p-7 md:flex-1 md:-mt-2 lg:py-10 lg:px-18 relative;
    
    p {
      margin-bottom: 0;
    }
  }
}



.brand-block {
  @apply md:flex md:flex-wrap md:items-center md:mb-10;
  &__img { 
    @apply mb-4 md:mr-4 md:mb-0;
    
    
    @media only screen and (min-width: 767px) {
      width: 180px;
      flex-basis: 180px;
      
      @media only screen and (min-width: 1023px) {
        width: 330px;
        flex-basis: 330px;
        margin-right: 9%;
      }
    }
    
    img {
      max-width: none;
      width: 100%;
    }
  }
  
  &__text {
    @apply md:flex-1;
  }
  
  p {
    
    @apply pb-0;
  }
}

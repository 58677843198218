
.categories {
  @apply bg-blue-pattern py-11 lg:py-20 ;
  
  &__heading {
    @apply text-center md:text-4xl lg:text-5xl lg:mb-20;
  }
  
  
  
  &-list {
    
    @apply md:flex md:flex-wrap md:-mx-4 xl:-mx-12;
    
    &__item {
      @apply mb-4 md:px-4 xl:px-12 md:w-1/3 xl:mb-14;
    }
    
    
    &__link {
      @apply block border border-solid no-underline relative text-gray-secondary rounded-md text-xl lg:text-2xl py-5 px-14 md:border-2 lg:px-14 lg:py-6;
      border-color: #C4C4C4;
      
      &:hover {
        @apply text-white bg-blue border-blue;
        
        .categories-list__icon {
          fill: #ffffff;
        }
      }
    }
    
    &__icon {
      @apply absolute right-9 top-1/2 transform -translate-y-1/2 w-5 h-5 xl:w-6 xl:h-6;
    }
  }
}

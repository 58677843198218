
.education {
  @apply tracking-widest bg-cover bg-no-repeat bg-center text-white flex flex-col flex-wrap;
  
  &__container {
    min-height: 474px;
    max-width: 1360px;
    @apply flex flex-wrap items-center ;
  }
  
  &__title {
    @apply mb-6  md:text-4xl lg:text-5xl lg:mb-10;
  }
  
  &__text {
    @apply md:w-6/12 md:ml-auto md:px-4 lg:px-8 xl:px-24;
  }
}

.breadcrumbs {
  @apply flex flex-wrap -mx-3 mb-16;
  
  &__item {
    @apply px-3 text-blue-secondary relative;
    
    & + &:before {
      content: '/';
      @apply absolute left-0 top-1/2 transform -translate-y-1/2;
    }
    
  }
  
  &__link {
    @apply text-gray no-underline;

    &:hover {
      @applu text-blue-secondary;
    }
  }
}

* {
  box-sizing: border-box;
}
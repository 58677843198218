.hero-block {
  @apply bg-cover bg-no-repeat bg-center relative z-1 text-center text-white py-25 lg:text-xl ;
  
  &--container {
    max-width: 1495px;
    @apply mx-auto md:py-14 lg:py-14 xl:my-16 xl:mb-28;
  }
  
  &__title {
    @apply mb-4 md:text-4xl lg:text-5xl ;
  }
  
  &__caption {
    @apply mx-auto;
    
    @media only screen and (min-width: 1023px) {
      max-width: 580px;
    }
  }
  
  p {
    @apply mb-15 xl:mb-12;
  }
  
  &:before {
    content: '';
    @apply absolute inset-0 -z-1 bg-blue bg-opacity-70;
  }
}

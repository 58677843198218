/* styles for boilerplate homepage. delete before you start developing! */

body {
  @apply font-sans text-lg leading-relaxed text-gray;
  line-height: 1.78;
}

h2 {
  @apply text-3xl mb-0 mb-8 ;
}

header {
  @apply flex justify-center ;
}

footer {
  @apply my-8 text-center;
}

p {
  @apply mb-8;

  a {
    @apply font-bold;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.banner {
  @apply h-screen flex flex-wrap items-center text-white text-center font-serif leading-relaxed relative z-10 bg-cover bg-no-repeat bg-center;
  
  &:before {
    content: '';
    @apply bg-gray-secondary absolute inset-0 opacity-30;
    mix-blend-mode: mulitply;
    z-index: -1;
  }
  
  &__holder {
    @apply relative md:px-14;
  }
  
  &__title {
    @apply text-5xl font-black mb-10 md:text-6xl lg:text-7xl xl:text-8xl xl:leading-relaxed md:mx-auto;
    
    @media only screen and (min-width: 767px) {
      max-width: 1110px;
    }
  }
  
  &__text {
    @apply inline-block tracking-widest text-white no-underline hover:opacity-80 md:text-3xl lg:text-4xl font-bold md:w-8 md:absolute md:right-0 md:top-1/2  md:transform md:-translate-y-1/2 font-sans;
    
    @media only screen and (min-width: 767px) {
      line-height: 46px;
    }
    
    
    &-icon {
      fill: #fff;
      @apply w-5 block mx-auto lg:w-6 h-14 mt-12 lg:h-16;
      
      @media only screen and (min-width: 1199px) {
        margin-top: 147px;
      }
      
    }
  }
}
.two-col {
  
  @apply text-left md:py-6 xl:py-16 md:flex md:flex-wrap md:-mx-4 -mb-8 md:mb-0 ;
  
  &__text {
    @apply md:w-1/2 md:pt-2 md:px-4 mb-8 md:mb-0;
  }
  
  &__img {
    @apply md:w-1/2 md:px-4 mb-8 md:mb-0;
  }
  
  &__hold {
    max-width: 600px;
  }
  
  
  &__title {
    font-size: 28px;
    @apply m-0 tracking-wide mb-9 md:text-3xl lg:text-5xl lg:mb-11;
  }
  
  &__paragraph {
    @apply text-lg leading-relaxed m-0 mb-9 md:text-xl md:leading-relaxed text-gray-secondary;
  }
  
  &__img1 {
    padding-right: 22%;
    @apply relative z-10;
    
    @media only screen and (min-width: 767px) {
      padding-right: 22.5%;
      padding-left: 2.3%;
    }
    
    &:before {
      content: '';
      background: #F1F6EF;
      right: 16%;
      top: -20px;
      @apply w-14 h-14 absolute ;
      z-index: -1;
      
      
      @media only screen and (min-width: 1023px) {
        width: 116px;
        height: 122px;
        top: -48px;
      }
    }
    
    &:after {
      content: '';
      @apply bg-green-secondary absolute left-0 right-0 h-9 lg:h-20;
      bottom: -7%;
      left: -16px;
      z-index: -1;
      
      @media only screen and (min-width: 1023px) {
        bottom: -9%;
        left: -45px;
      }
      
    }
    
    
    img {
      @apply w-full;
      max-width: none;
    }
  }
  
  &__img2 {
    margin: -20% -11px 0 0 ;
    width: 55%;
    @apply ml-auto relative z-10;
    
    @media only screen and (min-width: 1023px) {
      width: 52%;
      margin-top: -18%;
    }
    
    img {
      @apply w-full;
      max-width: none;
    }
    
    
  }
}


.column {
  
  @apply md:flex md:flex-wrap md:-mx-4 text-gray-secondary;
  
  &--end {
    @apply md:items-end;
  }
  
  &__item {
    @apply mb-6 text-lg leading-relaxed md:w-1/2 md:px-4;
  }
  
  &__title {
    @apply text-3xl md:text-4xl lg:text-5xl lg:mb-12 text-gray;
  }
  
  &__hold {
    @media only screen and (min-width: 1199px) {
      padding-left: 19.9%;
    }
  }
  
  &__img {
    
    @apply md:text-right;
    
    @media only screen and (min-width: 1199px) {
      padding-top: 8.8%;
      
    }
  }
  
  p {
    @apply mb-6 lg:text-xl lg:leading-relaxed xl:mb-24;
  }
}


.two-block {
  @apply -mt-1 xl:py-14 md:flex md:flex-wrap;
}
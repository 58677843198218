
.content-block {
  @apply bg-blue text-white -mx-4 md:mx-0 relative;
  
  
  &__text {
    @apply px-4 py-14 tracking-normal md:w-1/2 lg:w-5/12 md:ml-auto ;
    
    
    @media only screen and (min-width: 1199px) {
      padding: 72px 108px;
    }
  }
  
  &__title {
    @apply text-3xl font-bold mb-10 md:text-4xl lg:text-5xl;
  }
  
  p {
    @apply mb-14 lg:text-xl leading-relaxed lg:mb-20;
  }
  
  &__img {
    height: 394px;
    @apply overflow-hidden bg-cover bg-center bg-no-repeat md:w-1/2 lg:w-7/12 md:absolute md:left-0 md:top-0 md:bottom-0 md:h-auto;
  }
}

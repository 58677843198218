
.page-404 {
  @apply h-screen flex flex-wrap justify-center items-center text-center text-xl lg:text-3xl;
  min-height: 482px;
  
  @media only screen and (min-width: 767px) {
    min-height: 600px;
  }
  
  &__title {
    @apply text-blue-secondary block font-bold mb-10 lg:mb-20;
    font-size: 32px;
  }
  
  &__img {
    @apply mb-10 lg:mb-20;
  }
  
  &__paragraph {
    @apply mb-4 lg:mb-16;
  }
}

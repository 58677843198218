

.table-label{
  
  @apply block pt-3 -mb-2 lg:flex-1 lg:pt-0;
  
  &__item {
    @apply bg-gray-light text-white inline-block px-2 rounded-sm mr-2 mb-2;
  }
}


.table__title {
  @media only screen and (min-width: 1023px) {
    width: 130px;
    flex-basis: 130px;
    
  }
  
  .table__td1 & {
    @media only screen and (min-width: 1023px) {
      width: 75px;
      flex-basis: 75px;
    }
    
    @media only screen and (min-width: 1199px) {
      width: 90px;
      flex-basis: 90px;
    }
  }
}

.table-list {
  @apply -mb-20 xl:-mb-32;
  
  &__item {
    @apply mb-20 xl:mb-32;
  }
}

table {
  table-layout: fixed;
  @apply bg-blue-pattern w-full text-base xl:text-lg md:text-left;
  
  tbody {
    tr {
      &:nth-child(2n) {
        td {
          @apply md:bg-blue-lighter;
        }
      }
    }
  }
  
  td,
  th {
    @apply md:border-0 md:border-b md:border-solid md:border-white md:bg-blue-pattern align-middle;
    
    &:last-child {
      @apply md:pr-4;
    }
    
    > span {
      @apply lg:flex lg:flex-wrap lg:items-center;
    }
    
    @media only screen and (min-width: 1023px) {  
      padding: 23px 0 23px 15px;
      
      &.table__td1 {
        width: 17.9%;
      }
      
      &.table__td2 {
        width: 21.1%;
      }
      
      &.table__td3 {
        width: 15.9%;
      }
      
      &.table__td4 {
        width: 18.2%;
      }
      
      &.table__td5 {
        width: 26.9%;
      }
      
      &.table__td6 {
        width: 16.6%;
      }
      
      &.table__td7{
        width: 18.3%;
      }
      
      &.table__td8 {
        width: 32.3%;
      }
      
      &.table__td9 {
        width: 32.8%;
      }
      
      
    }  
    
    @media screen and (min-width: 1400px){
      padding-left: 31px;
    }
    
  }
  
  th {
    @apply md:bg-gray-secondary;
    
    @media only screen and (min-width: 1023px) {
      padding: 18px 0 18px 15px;
    }
    
    @media screen and (min-width: 1400px){
      padding-left: 31px;
    }
  }
  
  @media screen and (max-width: 1023px) {
    display: block;
    
    thead {
      display: none;
    }
    
    tbody {
      display: block;
    }
    
    tr {
      display: block;
      border-bottom: 3px solid #fff;
    }
    
    td {
      border-bottom: 1px solid #fff;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      
      > span {
        flex: 1;
        display: block;
        padding: 18px 15px 18px 10px;
      }
      
      &:before {
        content: attr(data-title);
        @apply bg-gray-secondary p-4 text-white  w-16 bottom-0;
        flex-basis: 64px;
      }
    }
  }
  
  thead {
    @apply bg-gray-secondary text-white;
  }
}

.link {
  @apply flex flex-wrap -mx-2 mb-4 md:border-b border-solid border-border md:mx-0 md:mb-8 lg:mb-9;
  
  &__item {
    @apply px-2 pb-4 md:p-0;
    
    &.active {
      .link__txt {
        @apply bg-blue text-white border-transparent;
        
        @media only screen and (min-width: 767px) {
          color: #4C5BB4;
          background: #F5F6F9;
          border-color: #4C5BB4;
        }
      }
    }
  }
  &__txt {
    @apply transition inline-block bg-white border-border border-solid border-2 rounded no-underline text-gray-secondary py-1 px-3 md:rounded-none md:border-0 md:border-t-4 md:border-transparent lg:text-xl lg:px-7 lg:py-3;
    
    
    &:hover {
      @apply bg-blue text-white border-transparent;
      
      @media only screen and (min-width: 767px) {
        color: #4C5BB4;
        background: #F5F6F9;
        border-color: #4C5BB4;
      }
    }
  }
}



.footer {
  @apply bg-gray text-white my-0 py-15 text-left lg:py-25 lg:pb-10;
  
  &__row {
    @apply lg:flex lg:flex-wrap;
  }
  
  &__col {
    @apply lg:pr-8;
    
    @media only screen and (min-width: 1023px) {
      width: 41.3%;
    }
  }
  
  &__logo {
    width: 185px; 
    @apply mb-5 lg:mb-6;
    
    @media only screen and (min-width: 1023px) {
      width: 220px;
    }
  }
  
  &__address {
    @apply text-base leading-loose tracking-wider block mb-9;
  }
  
  &__title {
    @apply relative text-lg border-b border-solid border-gray-secondary block py-4 pr-10 lg:border-transparent lg:p-0 lg:mb-5;
    cursor: pointer;
    
    @media only screen and (min-width: 1023px) {
      cursor: default;
    }
    
    .active & {
      .footer__icon {
        @apply rotate-90;
      }
    }
  }
  
  &__icon {
    fill: #fff; 
    width: 18px;
    height: 18px;
    transition: transform 0.3s;
    @apply absolute right-0 top-1/2 transform -translate-y-1/2 lg:hidden;
  }
  
  &__slide {
    @apply  overflow-hidden;
    transition: max-height 0.3s;
    
    
    @media only screen and (min-width: 1023px) {
      max-height: none !important;
    }
  }
  
  &__item {
    
    @apply lg:pt-3 lg:pr-5;
    
    @media only screen and (min-width: 1023px) {
      width: 19.5%;
    }
  }
}

.footer-contact {
  @apply text-base;
  
  &__item {
    @apply mb-1;
  }
  
  &__link {
    @apply text-white no-underline ml-1 hover:text-green;
  }
}

#footer {
  @apply my-0;
  
  .container {
    max-width: 1273px;
  }
}


.footer-list {
  @apply py-4 text-base;
  
  
  &__item {
    @apply mb-1 lg:mb-5;
  }
  
  &__link {
    @apply text-white no-underline hover:text-green;
  }
}

.footer-block {
  background: #313439;
  @apply text-gray-light text-sm py-9 lg:py-8;
  
  &__row {
    @apply md:flex md:flex-wrap md:items-center;
  }
}

.footer-block__col {
  @apply md:w-3/12;
}

.footer-links {
  @apply flex flex-wrap -mx-3 justify-center mb-4   md:mb-0 md:justify-end;
  
  &__link {
    @apply text-gray-light no-underline hover:text-green px-3  hover:text-green;
  }
  
  &__item {
    @apply relative;
    
    & + &:before {
      content: '';
      height: 14px;
      @apply w-px bg-gray-light absolute left-0 top-1/2 transform -translate-y-1/2;
    }
  }
}

.copyright {
  @apply block md:order-first md:w-9/12 md:pr-5 md:text-left;
}

.back-to-top {
  @apply text-white no-underline fixed right-5 bottom-28 z-50 text-xl transition hover:opacity-80 w-12 h-12 bg-green rounded-full opacity-0 invisible transition;
  text-shadow: 2px 1px rgba(0,0,0,0.5);


  &.show {
    @apply opacity-100 visible;
  }
  
  &:before {
    content: '';
    @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-1 -mt-0.5;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9.5px 12px 9.5px;
    border-color: transparent transparent #ffffff transparent;
  }

}
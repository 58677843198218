.visual {
  @apply bg-cover bg-no-repeat bg-center py-5  text-white relative z-10 ;
  
  &:before {
    content: '';
    @apply absolute left-0 right-0 top-0 bottom-0 bg-blue opacity-60;
    z-index: -1;
  }
  
  &--green {
    &:before {
      background: #165230;
    }
  }
  
  &__holder {
    @media only screen and (min-width: 1023px) {
      max-width: 720px;
    }
  }
  
  &__container {
    @apply flex flex-wrap items-center;
    min-height: 360px;
  }
  
  &__heading {
    font-size: 32px;
    @apply mb-5 font-bold md:text-4xl lg:text-5xl lg:mb-10;
    
    &:before {
      content: '';
      width: 74px;
      @apply h-1 bg-green-secondary block mb-1 md:w-24 md:mb-8;
    }
  }
  
  
  
  &__paragraph {
    @apply mt-0 mb-4 text-xl tracking-normal md:text-lg;
  }
}



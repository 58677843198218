.sidebar {
  @apply hidden md:block md:mr-4;
  
  @media only screen and (min-width: 767px) {
    width: 180px;
    flex-basis: 180px;
  }
  
  @media only screen and (min-width: 1023px) {
    width: 216px;
    flex-basis: 216px;
  }
  
  &__title {
    @apply lg:text-xl border-border border-b border-solid block pb-1 mb-8; 
  }
  
  &-list {
    @apply lg:text-xl ;
    max-width: 174px;
    
    &__item {
      @apply mb-1;
      
      &.active {
        .sidebar-list__link {
          @apply bg-blue-pattern text-blue-secondary;
        }
      }
    } 
    
    &__link {
      @apply no-underline text-gray-secondary hover:bg-blue-pattern hover:text-blue-secondary block px-4 py-3;
    }
  }
  
  
}

/* tailwind always at the top! */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* all other imports below.
no css should be in this file */

/* Base Styles */
@import './base/variables.css';
@import './base/reset.css';
@import './base/typography.css';

/* Import vendor styles */
@import 'swiper/swiper-bundle.css';

@import 'vendor/swiper.css';

/* Import boilerplate style */
@import './components/boilerplate.css';

/* Import components */
@import './layouts/header.css';
@import './layouts/footer.css';
@import './layouts/sidebar.css';


@import './components/visual.css';
@import './components/breadcrumbs.css';
@import './components/two-col.css';
@import './components/text-block.css';
@import './components/img-block.css';
@import './components/testimonial.css';
@import './components/content-block.css';
@import './components/education.css';
@import './components/icon-block.css';
@import './components/contact.css';
@import './components/comment.css';
@import './components/banner.css';
@import './components/icon-list.css';
@import './components/welfare.css';
@import './components/life.css';
@import './components/brand.css';
@import './components/table.css';
@import './components/tab.css';
@import './components/service.css';
@import './components/accordion.css';
@import './components/value.css';
@import './components/partner.css';
@import './components/hero.css';
@import './components/categories.css';
@import './components/gray.css';
@import './components/num-list.css';



/* Import page styles */
@import './pages/page-404.css';











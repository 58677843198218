.img-block {
  @apply pr-20 relative z-10 overflow-visible md:flex md:flex-wrap md:pr-0;
  
  &:before {
    @apply absolute  top-1/2  bg-white md:-mx-2 lg:-mx-5;
    content: '';
    left: -9999px;
    right: -9999px;
    height: 9999px;
    z-index: -1;
  }
  
  &__item {
    @apply md:w-1/4 md:px-2 lg:px-5;
  }
  
  &__img {
    height: 323px;
    @apply z-10 relative bg-cover bg-no-repeat bg-center block flex flex-wrap items-end no-underline text-white px-6 py-4 tracking-widest;
    
    @media only screen and (min-width: 1199px) {
      height: 474px;
    }
    
    &:before {
      content: '';
      background: rgb(38,41,46);
      background: linear-gradient(180deg, rgba(38,41,46,0) 0%, rgba(38,41,46,0.8) 100%);
      @apply absolute left-0 right-0 top-0 bottom-0;
      z-index: -1;
    }
  }
  
  &__title {
    @apply block w-full ;
  }
}
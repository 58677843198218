.contact {
  @apply md:pt-8 md:flex md:flex-wrap;
  
  &__title {
    @apply md:text-4xl lg:text-5xl;
  }
  
  &__paragraph {
    @apply text-xl mb-12 lg:text-3xl lg:mb-20;
  }
  
  &__text {
    @apply  md:w-5/12;
  }
}


.contact-list {
  @apply text-lg tracking-wider;
  
  &__item {
    @apply mb-3 md:flex md:flex-wrap md:mb-5;
  }
  
  &__title {
    @apply block text-blue md:w-12 md:mr-1;
  }
  
  &__text {
    @apply md:flex-1;
  }
  
  &__link {
    @apply no-underline text-gray font-medium hover:text-blue;
  }
}

.form {
  @apply pt-12 w-7/12 md:pt-0 md:pl-4 lg:px-20;
  
  
  &__group {
    @apply mb-4 lg:mb-8;
    
    
    
    &--success {
      .form__input {
        @apply border-green;
      }
    }
    
    &--error {
      
      .form__input {
        @apply border-red;
      }
      
      
      .form__error {
        @apply block;
      }
    }
  }
  
  &__error {
    @apply text-red text-lg font-normal block hidden;
    
  }
  
  &__label {
    @apply text-base font-normal text-gray inline-block mb-2 lg:text-xl lg:mb-3;
  }
  
  &__input {
    outline: none;
    @apply block w-full h-12 text-base font-normal px-4 py-2 lg:h-16 lg:text-lg border border-solid border-border bg-white;
    box-shadow: none;
  }
  
  textarea&__input {
    height: 193px;
    overflow-y: auto;
    @apply py-4;
  }
  
  &__label-checkbox {
    @apply relative text-center  align-middle pt-9 pb-10 md:text-left;
    
    input {
      @apply w-0 h-0 absolute left-0 top-0;
      
      &:checked {
        ~ .form__label-checkbox-slide:before {
          @apply opacity-100;
        }
      }
    }
    
    &-slide {
      @apply -mt-1 inline-block align-middle w-5 h-5 rounded-full border-2 border-solid border-blue relative; 
      
      &:before {
        content: '';
        @apply bg-blue absolute left-0.5 right-0.5 top-0.5 bottom-0.5 rounded-full opacity-0 transition ;
      }
    }
  }
  
  &__success-text {
    @apply relative pl-14 text-green py-1 mb-14;
  }
  
  &__success-img {
    @apply absolute left-0 top-0;
  }
}

select::-ms-expand {
  display: none;
}



.partner {
  @apply text-center md:flex md:flex-wrap md:-mx-4 items-center;
  
  &__item {
    @apply mb-15 md:w-1/4 md:px-4;
  }
  
  
  &__link {
    @apply block mx-auto;
    max-width: 74%;
    
    @media only screen and (min-width: 767px) {
      max-width: none;
    }
  }
}


.swiper-container-horizontal > .swiper-pagination  {
  @apply absolute left-0 right-0 text-center bottom-0;
  
  
  .swiper-pagination-bullet {
    @apply w-3 h-3 rounded-full bg-gray-lighter mx-1 opacity-100 lg:mx-2;
    background: #C8CED8;
    
    @media only screen and (min-width: 1023px) {
      width: 14px;
      height: 14px;
    }
    
    &.swiper-pagination-bullet-active,
    &:hover {
      @apply bg-gray-light;
    }
    
  }
}




.slider {
  @apply text-white text-xl leading-relaxed pb-15 mb-18 lg:text-2xl lg:pb-16 lg:mb-25;
  
  &__img {
    @apply bg-cover bg-no-repeat bg-cover px-10 py-11 relative z-10;
    
    @media only screen and (min-width: 1199px) {
      padding: 91px 133px 114px;
    }
    
    &:before {
      content: '';
      background: rgb(81,88,101);
      background: linear-gradient(90deg, rgba(81,88,101,0.3) 0%, rgba(81,88,101,0.9) 100%);
      @apply absolute inset-0;
      z-index: -1;
      mix-blend-mode: multiply;
    }
  }
  
  &__subtitle {
    @apply text-xl block tracking-wider mb-4 lg:text-xl;
  }
  
  &__title {
    @apply text-5xl font-bold mb-4 lg:mb-6;
  }
  
  
  &__txt {
    @apply mb-10;
    margin-bottom: 140px;
    max-width: 320px;
    
    @media only screen and (min-width: 1023px) {
      margin-bottom: 90px;
    }
  }
}

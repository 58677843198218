
.service {
  @apply lg:pb-7;
  
  @media only screen and (min-width: 1199px) {
    padding-top: 124px;
  }
  
  &__heading {
    @apply text-center tracking-tighter md:text-4xl lg:text-5xl lg:mb-16;
    
    
  }
}

.service-list {
  @apply flex flex-wrap;
  
  &__img {
    @apply mx-auto mb-3 md:mb-5; 
    width: 90px;
    height: 90px;
    
    @media only screen and (min-width: 1023px) {
      width: 124px;
      height: 124px;
    }
  }
  
  &__item {
    @apply  text-center w-1/2 mb-14 md:w-1/6;
  }
  
  &__link {
    @apply no-underline text-gray-lighter font-medium text-lg hover:text-blue lg:text-2xl;
  }
}


.service {
  @apply py-9 lg:py-15;
  
  .service__title {
    @apply text-center text-3xl pt-0.5 mb-8 md:text-left md:text-4xl lg:text-5xl lg:mb-14;
  }
  
  &__info {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      
      h2 {
        text-align: center;
        font-size: 28px;
      }
    }
    
    .two-col__img {
      @media screen and (max-width: 767px) {
        order: 1;
      }
    }
    
    .two-col__paragraph {
      @media screen and (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
  }
  
  .tab__content {
    @apply md:w-full;
    
    @media only screen and (min-width: 767px) {
      max-width: 570px;
    }
  }
  
  &s {
    
    
    &__item {
      @apply text-base md:flex mb-8 lg:text-base lg:mb-5;
      letter-spacing: 0.09375rem;
      
      &__icon {
        @apply w-15 h-15 bg-blue md:flex-shrink-0 flex items-center justify-center p-2.5 mb-2 mr-8 rounded-xl lg:w-18 lg:h-18 lg:mr-6;
      }
      
      &__title {
        @apply text-blue font-normal text-lg mb-2 lg:text-2xl ;
        letter-spacing: 0.0625rem;
      }
      
      &__detail p {
        @apply mb-0 lg:text-base text-gray-secondary;
      }
    }
    
    
  }
  
  .service__tab {
    &set {
      @apply md:w-full text-base md:text-sm xl:text-22px font-serif font-medium flex flex-nowrap mb-9 p-0 border-b border-solid xl:mb-11 border-gray-light;
      overflow: hidden;
      overflow-x: auto;
      
      @media only screen and (min-width: 1023px) {
        max-width: 640px;
      }
      
      li {
        @apply mr-10 md:mr-4 xl:mr-14;
      }
      
      a {
        @apply text-gray no-underline whitespace-nowrap md:whitespace-normal block pb-2.5 border-b-4 border-solid border-transparent lg:pb-4;
      }
      
      .active {
        @apply text-blue-secondary border-blue-secondary;
      }
    }
    
    &__item:not(.active) {
      @apply opacity-0 absolute invisible;
    }
    
    
  }
}
